import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import img404 from '../images/icons/404.svg';
import { graphql, Link } from 'gatsby';
import meta from '../constants/meta-descriptions';

const OpsText = styled.h2`
	color: white;
	font-size: 80px;
	line-height: 140%;
	font-family: Comfortaa;
	font-style: normal;
	font-weight: bold;
`;
const SecondaryText = styled.h2`
	color: white;
	font-size: 40px;
	line-height: 140%;
	font-family: Comfortaa;
	font-style: normal;
	font-weight: bold;
`;

const PageWrapper = styled.div`
	background-image: url(${(props) => props.bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	padding: 2rem 0 2rem 0;
`;

const NotFoundPage = ({ data }) => (
	<div style={{ backgroundColor: '#151F41' }}>
		<Layout>
			<PageWrapper bgImg={data.bgImage.childImageSharp.fluid.src}>
				<SEO
					title={meta.notfound.title}
					description={meta.notfound.metaDescription}
				/>
				<div className="container text-center" style={{ paddingTop: '16rem' }}>
					<OpsText>Опаааа</OpsText>
					<SecondaryText>Тук няма нищо...</SecondaryText>
					<img
						src={img404}
						className="img-fluid"
						alt="404 нищо не е намерено"
					/>
					<div className="my-5">
						<Link to="/" className="btn btn-primary green">
							Начало
						</Link>
					</div>
				</div>
			</PageWrapper>
		</Layout>
	</div>
);

export const query = graphql`
	query Query404 {
		bgImage: file(relativePath: { eq: "404-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 900) {
					src
				}
			}
		}
	}
`;

export default NotFoundPage;
